import React, { Component } from "react";
import Layout from "./components/layout/Layout";
import Intro from "./components/Intro";
import ArtikelInfo from "./components/ArtikelInfo";

class App extends Component {
  render() {
    return (
      <Layout>
        <Intro />
        <ArtikelInfo prodorder={window.location.pathname.replace("/", "")} />
      </Layout>
    );
  }
}

export default App;
