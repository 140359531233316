import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledIntro = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.red};
  color: ${props => props.theme.white};
`;

const Intro = () => {
  const { t } = useTranslation();
  return (
    <StyledIntro>
      <p>{t("intro")}</p>
      {/* <p>{t("intro_2")}</p>
      <p>{t("intro_3")}</p> */}
    </StyledIntro>
  );
};

export default Intro;
