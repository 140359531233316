import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ArtikelLinks from "./ArtikelLinks";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import NProgress from "nprogress";

const StyledArtikelInfo = styled.div`
  h1 {
    font-size: 2.75rem;
    margin-bottom: 0px;
  }
  h2 {
    margin-top: 0px;
    font-size: 1.5rem;
  }
`;

const StyledArtikelGegevens = styled.div`
  padding: 24px 15px;
`;

const StyledArtikelLinks = styled.div`
  padding: 24px 15px;
  margin: 20px 0px;
  background-color: ${props => props.theme.blueGrey};
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.black};
  background-color: ${props => props.theme.blueGrey};
  padding: 10px;
  margin: 24px 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
`;

const baseUrl = "https://apps.ebema.com/api/portalapi/api/v1/";

const ArtikelInfo = props => {
  const { t } = useTranslation();
  const [artikelInfo, setArtikelInfo] = useState({});
  const [errorInfo, setErrorInfo] = useState("");
  const [errorGeenProdorder, setErrorGeenProdorder] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getArtikelInfo(prodordernr) {
    try {
      if (prodordernr !== "") {
        setErrorGeenProdorder(false);
        NProgress.start();
        const res = await axios.get(
          `${baseUrl}label/QrCodeInfo/${prodordernr}`
        );
        setArtikelInfo(res.data);
      } else {
        setErrorGeenProdorder(true);
      }
    } catch (error) {
      if (error.response) {
        setErrorInfo(`${error.response.data.error}`);
      } else {
        setErrorInfo(`${error.message}`);
      }
    }
    setLoading(false);
    NProgress.done();
  }
  useEffect(() => {
    getArtikelInfo(props.prodorder);
  }, []);

  return (
    <StyledArtikelInfo>
      {!loading && errorInfo && (
        <ErrorMessage>
          {t("error")} {errorInfo}
        </ErrorMessage>
      )}
      {!loading && errorGeenProdorder && (
        <ErrorMessage>{t("prodordernr-ontbreekt")}</ErrorMessage>
      )}
      <StyledArtikelGegevens>
        <h1>{artikelInfo.artikelnr}</h1>
        <h2>
          {i18next.language === "nl"
            ? artikelInfo.verkooptekstNl
            : artikelInfo.verkooptekstFr}
        </h2>
      </StyledArtikelGegevens>
      {!loading && !errorInfo && !errorGeenProdorder && (
        <StyledArtikelLinks>
          <h1>{t("info-beschikbaar")}</h1>
          <ArtikelLinks artikelInfo={artikelInfo} />
        </StyledArtikelLinks>
      )}
    </StyledArtikelInfo>
  );
};

export default ArtikelInfo;
