import React, { Component } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

const theme = {
  red: "#da291c",
  white: "#fff",
  blueGrey: "#EDF1FA",
  black: "#000",
  maxWidth: "1000px",
  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)"
};

const StyledPage = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Side nav is hidden on mobile */
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
  height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
   html {
    box-sizing: border-box;
    font-size: 10px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: "ClanPro";
  }
  a {
    text-decoration: none;
    color: ${theme.black};
  }
  @font-face {
    font-family: "ClanPro";
    src: url("/assets/font/ClanPro-Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
`;

class Page extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <StyledPage>
          <GlobalStyle />
          <Header />
          <Main>{this.props.children}</Main>
          <Footer />
        </StyledPage>
      </ThemeProvider>
    );
  }
}

export default Page;
