import React from "react";
import styled from "styled-components";

const StyledMain = styled.main`
  grid-area: main;
  background-color: ${props => props.theme.white};
  @media only screen and (min-width: 1000px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const Main = props => <StyledMain>{props.children}</StyledMain>;

export default Main;
