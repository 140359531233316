import React from "react";
import styled from "styled-components";
import FooterImage from "../../assets/img/footer-bg.png";
import { useTranslation } from "react-i18next";

const StyledFooter = styled.footer`
  height: 50px;
  background-size: 462px 46.8px;
  background-image: url(${FooterImage});
  background-repeat: repeat-x;
  background-position: top;
  margin-bottom: 10px;
`;

const StyledLinks = styled.div`
  padding: 5px 15px;
  a {
    color: #788490;
    text-decoration: none;
  }
  @media only screen and (min-width: 1000px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const StyledFooterInfo = styled.div`
  @media only screen and (min-width: 1000px) {
    text-align: left;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledFooter />
      <StyledFooterInfo>
        <StyledLinks>
          <a href={t("url_verkoopsvoorwaarde")}>{t("verkoopsvoorwaarde")}</a>
        </StyledLinks>
        <StyledLinks>
          <a href={t("url_contacteerons")}>{t("contacteerons")}</a>
        </StyledLinks>
      </StyledFooterInfo>
    </>
  );
};

export default Footer;
