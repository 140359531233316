import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import EbemaMainLogo from "../../assets/img/ebema-main_logo.jpg";

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  box-shadow: 0 1px 150px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  height: 70px;
  @media only screen and (min-width: 1000px) {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
`;

const Nav = styled.nav`
  cursor: pointer;

  :after {
    display: block;
    content: "";
    clear: both;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  ul li {
    float: left;
    position: relative;
  }

  ul li a {
    display: block;
    color: ${props => props.theme.black};
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-in-out;
  }

  ul li a:focus {
    color: white;
  }

  ul li a:not(:only-child)::after {
    padding-left: 4px;
    content: " ▾";
  }

  ul li ul li {
    min-width: 190px;
  }

  ul li ul li a {
    background: transparent;
    color: ${props => props.theme.black};
    border-bottom: 1px solid #dde0e7;
  }

  ul li ul li a:hover,
  ul li ul li a:focus {
    background: #eee;
    color: ${props => props.theme.white};
  }
`;

const Header = () => {
  const { t } = useTranslation();

  function changeLang() {
    if (i18next.language === "nl") {
      i18next.changeLanguage("fr");
    } else {
      i18next.changeLanguage("nl");
    }
  }

  return (
    <StyledHeader>
      <img src={EbemaMainLogo} alt="Ebema" width="150" />
      <Nav>
        <ul>
          <li>
            <a onClick={() => changeLang()}>{t("taal")}</a>
          </li>
        </ul>
      </Nav>
    </StyledHeader>
  );
};

export default Header;
