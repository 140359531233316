import React from "react";
import styled from "styled-components";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const StyledArtikelLinks = styled.div`
  a {
    color: #788490;
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0px;
    margin: 15px 0px 0px 0px;
  }
  li a {
    display: block;
    padding: 10px 0px;
  }
  hr {
    border-top: 1px solid #788490;
  }
`;

const ArtikelLinks = props => {
  const { t } = useTranslation();
  const dop = (
    <>
      <li>
        <a
          href={
            i18next.language === "nl"
              ? props.artikelInfo.dopUrlNl
              : props.artikelInfo.dopUrlFr
          }
        >
          Declaration of Performance (DoP)
        </a>
      </li>
      <hr />
    </>
  );
  const plaatsingsvoorschriften = (
    <li>
      <a
        href={
          i18next.language === "nl"
            ? props.artikelInfo.plaatsingUrlNl
            : props.artikelInfo.plaatsingUrlFr
        }
      >
        {t("plaatsingsvoorschriften")}
      </a>
      <hr />
    </li>
  );
  const bestektekst = (
    <li>
      <a
        href={
          i18next.language === "nl"
            ? props.artikelInfo.bestektekstUrlNl
            : props.artikelInfo.bestektekstUrlFr
        }
      >
        {t("bestektekst")}
      </a>
    </li>
  );
  return (
    <StyledArtikelLinks>
      <ul>
        {props.artikelInfo.dopUrlNl ? dop : ""}
        {props.artikelInfo.plaatsingUrlNl ? plaatsingsvoorschriften : ""}
        {props.artikelInfo.bestektekstUrlNl ? bestektekst : ""}
      </ul>
    </StyledArtikelLinks>
  );
};

export default ArtikelLinks;
