import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationNL from "./assets/lang/nl/translation-NL.json";
import translationFR from "./assets/lang/fr/translation-FR.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  nl: {
    translation: translationNL
  },
  fr: {
    translation: translationFR
  }
};

function getBrowserLanguage() {
  let lang = navigator.language || navigator.userLanguage;
  lang = lang.substring(0, 2);
  if (lang !== "nl" && lang !== "fr") {
    lang = "nl";
  }
  return lang;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getBrowserLanguage(),
    fallbackLng: "nl",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;
